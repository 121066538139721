import AdminContact from './components/panel/AdminContact.svelte'

$.fn.extend({
    'adminContact': function (this: JQuery, options) {
        options = $.extend({
            logoImgUrl: '',
            pageTitle: '',
            controlPanelUrl: '',
            contact: '',
        }, options)

        new AdminContact({
            target: this.get(0),
            props: options
        })
    }
})
