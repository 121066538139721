import RecentDetail from './components/recent/RecentDetail.svelte'
import {loadAndReplaceRecentData} from './lib/recent-data'

$.fn.extend({
    'recentdetail': function (this: JQuery, options) {
        options = $.extend({
            timestamp: 0,
            groupBoundary: [],
            list: [],
        }, options)

        this.each(function () {
            const hash = $(this).attr('data-recent-hash')
            const recentData = loadAndReplaceRecentData({
                timestamp: options.timestamp,
                list: options.list,
            }, hash, sessionStorage)

            new RecentDetail({
                target: this,
                props: {
                    recentChangeDataList: recentData.list,
                    groupBoundary: options.groupBoundary,
                }
            })
        })
    }
})
