<script lang="ts">
    import {onMount} from 'svelte'
    import FloatingButton from './lib/FloatingButton.svelte'
    import {downsideScrollPosition, ScrollHelperSelector} from './utils'

    export let selectors: ScrollHelperSelector

    // local state
    let scrollPosition: number = 0
    let scrollableHeight: number = downsideScrollPosition(selectors)

    // effect
    const resizeObserver = new ResizeObserver(updateScrollableHeight)
    function updateScrollableHeight() {
        scrollableHeight = downsideScrollPosition(selectors)
    }

    onMount(() => {
        resizeObserver.observe(document.body)
    })

    function scrollToBottom() {
        const position = downsideScrollPosition(selectors)
        window.scrollTo({ top: position, behavior: 'smooth' })
    }
</script>

<svelte:window bind:scrollY={scrollPosition} />

{#if (scrollPosition <= scrollableHeight - 10)}
    <FloatingButton label="下へ" type="square" on:click={scrollToBottom}>
        <i class="fas fa-arrow-down fa-lg"></i>
    </FloatingButton>
{/if}
