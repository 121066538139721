import {dateOf, formatDate} from './value-format'

export interface RecentChangeData {
    page: string
    link: string
    time: number
}

export interface RecentData {
    timestamp: number
    list: RecentChangeData[]
}

export const LOCAL_STORAGE_KEY_PREFIX = 'RecentData:'

export interface RecentDataStorage {
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
}

export function loadAndReplaceRecentData(currentData: RecentData, hash: string, storage: RecentDataStorage): RecentData {
    const key = LOCAL_STORAGE_KEY_PREFIX + hash;
    const rawSavedRecentData = storage.getItem(key);
    if (!rawSavedRecentData) {
        storage.setItem(key, JSON.stringify(currentData));
        return currentData;
    }

    const savedRecentData = JSON.parse(rawSavedRecentData) as RecentData;
    if (savedRecentData.timestamp >= currentData.timestamp) {
        return savedRecentData;
    } else {
        storage.setItem(key, JSON.stringify(currentData));
        return currentData;
    }
}

export function groupByDate(list: RecentChangeData[]): Record<string, { page: string; link: string }[]> {
    return list.reduce((acc, item) => {
        const date = formatDate(dateOf(item.time));
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push({ page: item.page, link: item.link });
        return acc;
    }, {} as Record<string, { page: string; link: string }[]>);
}

interface GroupedData {
    relativeBoundary: Record<string, { page: string; link: string }[]>;
    dailyBoundary: Record<string, { page: string; link: string }[]>;
}

export function groupRecentDataByBoundaries(list: RecentChangeData[], groupBoundaries: string[], now: Date = new Date()): GroupedData {
    const relativeBoundary: Record<string, { page: string; link: string }[]> = {};
    const dailyBoundary: Record<string, { page: string; link: string }[]> = {};

    list.forEach(item => {
        const itemDate = dateOf(item.time);
        const formattedDate = formatDate(itemDate);
        const boundaryRange = findBoundaryRangeIncluding(item.time, groupBoundaries, now);

        if (boundaryRange) {
            const relativeBoundaryLabel = boundary2label(boundaryRange);
            if (!relativeBoundary[relativeBoundaryLabel]) {
                relativeBoundary[relativeBoundaryLabel] = [];
            }
            relativeBoundary[relativeBoundaryLabel].push({ page: item.page, link: item.link });
        } else {
            if (!dailyBoundary[formattedDate]) {
                dailyBoundary[formattedDate] = [];
            }
            dailyBoundary[formattedDate].push({ page: item.page, link: item.link });
        }
    });

    return { relativeBoundary, dailyBoundary };
}

function findBoundaryRangeIncluding(itemTimestamp: number, boundaries: string[], now: Date): string|null {
    for (let i in boundaries) {
        const boundary = boundaries[i]
        if (boundary2timestamp(boundary, now) < itemTimestamp) {
            return boundary
        }
    }
    return null
}

function boundary2timestamp(boundary: string, now: Date): number {
    const m = boundary.match(/^([0-9]+)([mhdw])$/)
    if (!m) {
        return 0
    }
    const value = parseInt(m[1])
    const unit = m[2]

    const unitBase = {
        m: 60,
        h: 60 * 60,
        d: 24 * 60 * 60,
        w: 7 * 24 * 60 * 60,
    }

    return Math.floor(now.getTime() / 1000) - value * unitBase[unit]
}

function boundary2label(boundary: string): string {
    const m = boundary.match(/^([0-9]+)([mhdw])$/)
    if (!m) {
        return ""
    }
    const value = parseInt(m[1])
    const unit = m[2]

    const unitLabel = {
        m: "分",
        h: "時間",
        d: "日",
        w: "週間",
    }

    return value + unitLabel[unit] + '以内に更新'
}
