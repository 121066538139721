import LoadPanel from './components/panel/LoadPanel.svelte'

$.fn.extend({
    'loadpanel': function (this: JQuery, options) {
        options = $.extend({
            dataSelector: '',
            helpLink: '',
        }, options)

        const data = JSON.parse($(options.dataSelector).attr('data-pageload'))

        new LoadPanel({
            target: this.get(0),
            props: {
                data,
                helpLink: options.helpLink,
            }
        })
    }
})
