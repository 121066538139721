<script lang="ts">
    import FloatingButton from './lib/FloatingButton.svelte'

    // local state
    let scrollPosition: number = 0

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
</script>

<svelte:window bind:scrollY={scrollPosition} />

{#if (scrollPosition > 10)}
    <FloatingButton label="上へ" type="square" on:click={scrollToTop}>
        <i class="fas fa-arrow-up fa-lg"></i>
    </FloatingButton>
{/if}
