<script lang="ts">
    import {beforeUpdate, createEventDispatcher} from 'svelte'

    export let open: boolean = false
    export let side: 'left' | 'right' = 'right'
    export let bottomPadding: number | null = null

    // effect
    beforeUpdate(() => {
        // 本文領域のスクロールロック
        document.body.style.overflow = open ? 'hidden' : ''
    })

    const dispatch = createEventDispatcher()
    function close(e: MouseEvent) {
        e.preventDefault()
        dispatch('close')
    }

    // DOM を引き抜いて差し込むので、表示を切り替えるときは display を使ってコンポーネントが破棄されないよう注意!!
</script>
<div class="drawer" style:display={open ? 'block' : 'none'}>
    <a class="drawer-overlay" href="#close" on:click={close}>
        <!-- close overlay -->
    </a>
    <div class="drawer-content"
         class:right={side === 'right'}
         class:left={side === 'left'}
    >
        <button class="close-button" on:click={close}>
            <i class="fa fa-times fa-lg hamburger-off"></i>
        </button>
        <div class="drawer-content-inner" style:padding-bottom={bottomPadding ? bottomPadding + 'px' : null}>
            <slot>?</slot>
        </div>
    </div>
</div>
<style>
    .drawer {
        position: fixed;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        overflow: hidden;
        z-index: 1001;
    }
    .drawer-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        cursor: default;
    }
    .drawer-content {
        position: absolute;
        top: 0;
        width: auto;
        height: 100%;
        background-color: #eee;
        overflow: visible;
        padding: 0;
    }
    .drawer-content-inner {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        height: 100%;
    }

    .drawer-content.left {
        left: 0;
        animation: slide-in-from-left .15s ease-out;
    }

    .drawer-content.right {
        right: 0;
        animation: slide-in-from-right .15s ease-out;
    }

    @keyframes slide-in-from-left {
        from {
            margin-left: -20%;
            opacity: .8;
        }
        to {
            margin-left: 0;
            opacity: 1;
        }
    }

    @keyframes slide-in-from-right {
        from {
            margin-right: -20%;
            opacity: .8;
        }
        to {
            margin-right: 0;
            opacity: 1;
        }
    }

    .drawer-content .close-button {
        position: absolute;
        top: 0;
        border: 0;
        background-color: transparent;
        padding: 10px;
        color: white;
    }
    .drawer-content.left .close-button {
        right: -30px;
    }
    .drawer-content.right .close-button {
        left: -30px;
    }
</style>
