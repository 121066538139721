export interface ScrollHelperSelector {
    menubar: string,
    mainContentsBottom: string,
    sidebarBottom: string,
    footer: string,
}

const LAYOUT_BREAKPOINT = 820

// 仕様が複雑すぎて明確じゃないけどだいたい再現
// (高頻度に呼ぶのはしんどそうなのでキャッシュしたいな)
export function downsideScrollPosition(selectors: ScrollHelperSelector): number {
    const backScrollMarginMin = 200
    const backScrollMarginMax = 500
    const enoughHeightToAssumeContentAvailable = 32

    let position = document.documentElement.scrollHeight - document.documentElement.clientHeight

    // Desktop size
    const footer = document.querySelector(selectors.footer)
    if (footer) {
        position = offsetTop(footer) - backScrollMarginMin
    }
    if (!isNarrowDevice()) {
        return position
    }

    // Mobile size
    const menubar = document.querySelector(selectors.menubar)
    if (menubar) {
        // メニューバーより少し上を狙ってスクロールさせる。
        // ユーザー利便性を考えると、画面最上位より少し下がった位置のほうが認識しやすい。
        // というのと、ぴったりな位置になる場合と広告が入ってくる場合とが明確に分かれると白々しいので。
        const menubarBasedPos = offsetTop(menubar) - backScrollMarginMin
        if (menubarBasedPos < position) {
            position = menubarBasedPos
        }
    }

    // MenuBar が見えるという機能を満たす基本位置から離れられる限界までなら、広告を貪欲に見せるために位置を引き上げていい
    const basicFunctionalPos = position
    const backScrollLimit = basicFunctionalPos - (backScrollMarginMax - backScrollMarginMin)

    const pullUpTargetSelectors = [
        selectors.sidebarBottom,
        selectors.mainContentsBottom
    ]
    const pullUpTargetTopMargin = 10

    pullUpTargetSelectors.forEach(selector => {
        const pullUpTargetArea = document.querySelector(selector)
        if (pullUpTargetArea && pullUpTargetArea.clientHeight >= enoughHeightToAssumeContentAvailable) {
            const pullUpTargetAreaBasedPos = offsetTop(pullUpTargetArea) - pullUpTargetTopMargin
            if (
                pullUpTargetAreaBasedPos >= backScrollLimit &&
                pullUpTargetAreaBasedPos < position
            ) {
                position = pullUpTargetAreaBasedPos
            }
        }
    })

    return position
}

function isNarrowDevice() {
    return window.innerWidth < LAYOUT_BREAKPOINT
}

function offsetTop(element: Element): number {
    const rect = element.getBoundingClientRect()
    return rect.top + window.scrollY
}
