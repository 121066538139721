import Recent from './components/recent/Recent.svelte'
import {loadAndReplaceRecentData} from './lib/recent-data'

$.fn.extend({
    'recent': function (this: JQuery, options) {
        options = $.extend({
            timestamp: 0,
            list: [],
        }, options)

        this.each(function () {
            const hash = $(this).attr('data-recent-hash')
            const recentData = loadAndReplaceRecentData(options, hash, sessionStorage)

            new Recent({
                target: this,
                props: {
                    recentChangeDataList: recentData.list,
                }
            })

        })
    }
})
