<script lang="ts">
    import { onMount } from 'svelte'
    import DomSourceDrawerMenu from './DomSourceDrawerMenu.svelte'
    import ScrollToBottom from './ScrollToBottom.svelte'
    import ScrollToTop from './ScrollToTop.svelte'
    import {ScrollHelperSelector} from './utils'

    // props
    export let menuBarSelector: string
    export let editMenuSelector: string
    export let scrollBottomSelectors: ScrollHelperSelector
    export let overlayAd: {
        renderedEventName: string,
        marginTop: number,
    }

    // local state
    let isTextInputFocused: boolean = false
    let overlayAdBottomShift: number|null = null
    let windowHeight: number = window.innerHeight
    let shouldShowMenubarDrawerButton: boolean = menuBarDrawerVisibility()

    // refs
    let control: HTMLElement

    onMount(() => {
        watchOverlayAdDisplayed()
    })

    function isOutsideTextInput(element: any): boolean {
        if (control.contains(element)) {
            return false
        }
        return element instanceof HTMLInputElement ||
            element instanceof HTMLTextAreaElement ||
            (element instanceof HTMLElement && element.getAttribute('contenteditable') === 'true')
    }

    function onFocusIn(e: FocusEvent) {
        if (isOutsideTextInput(e.target)) isTextInputFocused = true
        // console.log('focus', e.target)
    }
    function onFocusOut(e: FocusEvent) {
        if (isOutsideTextInput(e.target)) isTextInputFocused = false
        // console.log('blur', e.target)
    }

    function watchOverlayAdDisplayed() {
        // オーバーレイ広告の render が終わったら、広告に重ならないように UI を上にずらしてやる
        // bottom: 175px; あるので多分大丈夫だとは思うけど...
        const eventName = overlayAd.renderedEventName as keyof DocumentEventMap
        document.addEventListener(eventName, (e) => {
            const requiredBottomMargin = (e.target as HTMLElement).offsetHeight + overlayAd.marginTop
            shiftUpBottom(requiredBottomMargin)
        })
    }

    function shiftUpBottom(requiredBottomMargin: number) {
        overlayAdBottomShift = Math.max(requiredBottomMargin, 175)
    }

    function updateMenuBarDrawerVisibility() {
        shouldShowMenubarDrawerButton = menuBarDrawerVisibility()
    }

    function menuBarDrawerVisibility() {
        const menuBar = document.querySelector(menuBarSelector)
        return menuBar ? menuBar.getBoundingClientRect().top - 20 >= windowHeight : false
    }

    // DOM を引き抜いて差し込むので、表示を切り替えるときは display を使ってコンポーネントが破棄されないよう注意!!
</script>

<svelte:window
    on:scroll={updateMenuBarDrawerVisibility}
    on:resize={updateMenuBarDrawerVisibility}
    bind:innerHeight={windowHeight}
/>
<svelte:document on:focusin={onFocusIn} on:focusout={onFocusOut} />

<div class="responsive-navigation-control" style:display={isTextInputFocused ? 'none' : 'block'} bind:this={control}>
    <div class="float-buttons-base" style:bottom={overlayAdBottomShift ? overlayAdBottomShift + 'px' : null}>
        <div id="responsive-navigation-drawer">
            <div id="responsive-navigation-menubar-drawer" class="button-space" style:display={shouldShowMenubarDrawerButton ? 'block' : 'none'}>
                <DomSourceDrawerMenu sourceSelector={menuBarSelector} label="メニュー" width={300} side="left" buttonType="square" bottomPadding={overlayAdBottomShift}>
                    <div class="menu-bar-button">
                        <div><i class="fa fa-lg fa-bars"></i></div>
                        <div class="menu-bar-button-label-small">MENU</div>
                    </div>
                </DomSourceDrawerMenu>
            </div>
            <div class="button-space">
                <DomSourceDrawerMenu sourceSelector={editMenuSelector} label="編集" width={260} side="right" bottomPadding={overlayAdBottomShift}>
                    <i class="fa fa-lg fa-pencil"></i>
                </DomSourceDrawerMenu>
            </div>
        </div>
        <div class="button-space extra">
            <ScrollToTop />
        </div>
        <div class="button-space">
            <ScrollToBottom selectors={scrollBottomSelectors}/>
        </div>
    </div>
</div>

<style>
    .responsive-navigation-control {
        position: fixed;
        width: 100vw;
        right: 0;
        bottom: 0;
        z-index: 1000;
        /*background-color: rgba(0, 0, 0, .25);*/
    }
    .float-buttons-base {
        position: absolute;
        right: 12px;
        bottom: 175px;
    }
    .button-space {
        margin: 10px 0;
        height: 40px;
    }
    .button-space.extra {
        margin-top: 20px;
    }
    .menu-bar-button {
        margin-top: -7px;
        margin-left: -12.5px;
        text-align: center;
        width: 40px;
    }
    .menu-bar-button-label-small {
        font-size: 8px;
        margin-top: -2px;
        white-space: nowrap;
    }
</style>
