<script lang="ts">
    import { createEventDispatcher } from 'svelte'

    export let label: string = ""
    export let type: "circle" | "square" = "circle"

    const dispatch = createEventDispatcher()

    const onClick = () => dispatch('click')
</script>
<button class="floating-button"
        class:square={type === 'square'}
        class:circle={type === 'circle'}
        on:click={onClick}
        title="{label}"
>
    <slot>?</slot>
</button>
<style>
    .floating-button {
        box-sizing: border-box;
        border: 0;
        border-radius: 5px;
        padding: 12px;
        background-color: rgba(0, 0, 0, .25);
        text-align: center;
        text-decoration: none;
        color: white;
        width: 40px;
        height: 40px;
        outline: none;
    }
    .floating-button:hover,
    .floating-button:focus,
    .floating-button:active {
        background-color: rgba(0, 0, 0, .30);
    }

    .floating-button.circle {
        border-radius: 50%;
    }

    .floating-button.square {
        border-radius: 6px;
    }
</style>
