export function zf2(n: number): string {
    return n.toString().padStart(2, "0")
}

export function formatTime(d: Date): string {
    return [
        zf2(d.getHours()),
        zf2(d.getMinutes()),
        zf2(d.getSeconds()),
    ].join(":")
}

export function formatDate(d: Date): string {
    return [
        d.getFullYear(),
        zf2(d.getMonth() + 1),
        zf2(d.getDate()),
    ].join("-")
}

export function formatDateWeekdayTime(d: Date): string {
    const weekday = ["日", "月", "火", "水", "木", "金", "土"]
    return [
        formatDate(d),
        '(' + weekday[d.getDay()] + ')',
        formatTime(d),
    ].join(" ")
}

export function dateOf(timestamp: number): Date {
    const d = new Date(timestamp * 1000)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return d
}
