<script lang="ts">
    import countResources from '../../lib/count-resources'
    import FloatingPanel from './FloatingPanel.svelte'

    interface TimeCost {
        real: number
        cost: number
    }

    interface LoadProfile {
        timestamp: string
        time: {
            all: TimeCost    // 実測全体
            body: TimeCost   // 実測本文
            main?: TimeCost  // 新規評価時のコスト
            menu?: TimeCost  // 新規評価時のコスト
            side?: TimeCost  // 新規評価時のコスト
            head?: TimeCost  // 新規評価時のコスト
            foot?: TimeCost  // 新規評価時のコスト
        }
        include: number
        memory: number
        io: {
            read: {
                files: number
                bytes: number
            }
            write: {
                files: number
                bytes: number
            }
        }
        contentSize: {
            all: number
            body: number
        }
    }

    interface WarningThreshold {
        danger?: number
        warning?: number
        safe?: number
    }

    const TIME_WARNING_THRESHOLD = {
        danger: 1.0,
        warning: 0.5,
        safe: 0.1,
    }

    const COST_WARNING_THRESHOLD = {
        danger: 4.0,
        warning: 2.0,
        safe: 0.4,
    }

    const INCLUDE_WARNING_THRESHOLD = {
        danger: 60,
        warning: 40,
        safe: 10,
    }

    const SIZE_WARNING_THRESHOLD = {
        danger: 1024 * 1024,
        warning: (512 + 256) * 1024,
    }

    const WARNING_LEVEL_CLASS = ['safe', '', 'warning', 'danger']

    export let data: LoadProfile
    export let helpLink: string

    const timeWarningLevel = warningLevel(data.time.main?.real ?? 0, TIME_WARNING_THRESHOLD)
    const timeClass = WARNING_LEVEL_CLASS[timeWarningLevel]

    const costWarningLevel = warningLevel(data.time.main?.cost ?? 0, COST_WARNING_THRESHOLD)
    const costClass = WARNING_LEVEL_CLASS[costWarningLevel]

    // インクルードにsafeの表示はない
    const includeWarningLevel = warningLevel(data.include, INCLUDE_WARNING_THRESHOLD) || 1
    const includeClass = WARNING_LEVEL_CLASS[includeWarningLevel]

    // コンテンツサイズにsafeの表示はない
    const sizeWarningLevel = warningLevel(data.contentSize.all, SIZE_WARNING_THRESHOLD) || 1
    const sizeClass = WARNING_LEVEL_CLASS[sizeWarningLevel]

    const includeOrSizeWarningLevel = Math.max(includeWarningLevel, sizeWarningLevel)
    const includeOrSizeClass = WARNING_LEVEL_CLASS[includeOrSizeWarningLevel]

    const timeAll =  formatNumber(data.time.all.real * 1000, 1)
    const nonMainTimeReal = [data.time.menu, data.time.side, data.time.head, data.time.foot].map(
        (x) => x?.real ?? 0
    ).reduce((a, x) => a + x, 0)
    const nonMainTimeCost = [data.time.menu, data.time.side, data.time.head, data.time.foot].map(
        (x) => x?.cost ?? 0
    ).reduce((a, x) => a + x, 0)
    const resources = countResources()

    function warningLevel(value: number|undefined, threshold: WarningThreshold): number {
        if (!value) {
            return 0
        }
        if (threshold.danger && value > threshold.danger) {
            return 3
        } else if (threshold.warning && value > threshold.warning) {
            return 2
        } else if (threshold.safe && value > threshold.safe) {
            return 1
        }

        return 0
    }

    // 警告文が出る理由を色で気づかせるための値
    function getTotalWarningLevel(data: LoadProfile): number {
        const summaryTimeWarningLevel = warningLevel(summarySec(data.time, 'real'), TIME_WARNING_THRESHOLD)
        const pureSizeWarningLevel = warningLevel(data.contentSize.all, SIZE_WARNING_THRESHOLD)
        const pureIncludeWarningLevel = warningLevel(data.include, INCLUDE_WARNING_THRESHOLD)
        const level = Math.max(summaryTimeWarningLevel, pureSizeWarningLevel, pureIncludeWarningLevel)
        // safe 表示になるのは main があるときのみ
        if (level === 0 && !data.time.main) {
            return 1
        }
        return level
    }

    function formatNumber(num: number, minorDigits: number): string {
        const d = Math.pow(10, minorDigits)
        return (Math.round(num * d) / d).toString()
    }

    function formatBytes(bytes: number): string {
        if (bytes === 0) {
            return "0"
        }
        const units = ['B', 'KB', 'MB', 'GB']
        let unit = units.shift()
        while (bytes >= 1024.0 && units.length > 0) {
            bytes /= 1024.0
            unit = units.shift()
        }
        const width = Math.floor(bytes).toString().length
        const minorDigits = Math.max(Math.min(3 - width, 2), 0)
        return formatNumber(bytes, minorDigits) + unit
    }

    function summarySec(t: LoadProfile['time'], target: keyof TimeCost): number {
        const { all, main } = t
        return Math.max(all[target], main ? main[target] : 0)
    }

    const style = {
        width: 'max-content',
        maxWidth: '350px',
    }
</script>

<FloatingPanel triggerColor={WARNING_LEVEL_CLASS[getTotalWarningLevel(data)]} panelId="load-panel" style={style}>
    <span slot="trigger" class="load-panel-trigger" title="HTML convert time">
        <i class="fas fa-tachometer-alt"></i>
        <span class="pageload-time-all-ms">{timeAll}ms</span>
    </span>
    <div slot="content" class="loadpanel-container">
        <div class="loadpanel">
            <div class="loadpanel-help">
                <a href={helpLink} target="_blank" title="ページ負荷について">
                    <i class="fas fa-question-circle"></i>
                </a>
            </div>
            <dl>
                <dt>
                    <span class="loadtime {timeClass}"><i class="fas fa-stopwatch"></i> 処理時間</span>
                </dt>
                <dd>
                    <span>
                        実測全体: {timeAll}ms<br>
                        <small>
                            {#if data.time.main}
                                <span class="loadtime {timeClass}">
                                    本文コスト: {formatNumber(data.time.main.real * 1000, 1)}ms
                                </span>
                                {#if nonMainTimeReal > 0}
                                    / その他コスト: {formatNumber(nonMainTimeReal * 1000, 1)}ms
                                {/if}<br>
                            {:else}
                                <span class="loadtime {timeClass}">
                                    実測本文: {formatNumber(data.time.body.real * 1000, 1)}ms
                                </span>
                            {/if}
                        </small>
                    </span>
                    <p class="loadtime-warning">
                        {#if timeWarningLevel === 3}
                            本文処理時間が {TIME_WARNING_THRESHOLD.danger * 1000}ms を超えています。<br>負荷軽減のためページ内容の見直しをお願い致します。
                        {/if}
                    </p>
                </dd>

                <dt><i class="fas fa-memory"></i> メモリ</dt>
                <dd>{formatBytes(data.memory)}<small>/req</small></dd>

                <dt><i class="far fa-hdd"></i> ファイルシステム</dt>
                <dd>
                    アクセス {data.io.read.files + data.io.write.files} 回
                    <small>(読:{data.io.read.files}/書:{data.io.write.files})</small>
                    <br>
                    データ量 {formatBytes(data.io.read.bytes + data.io.write.bytes)}
                    <small>(読:{formatBytes(data.io.read.bytes)}/書:{formatBytes(data.io.write.bytes)})</small>
                </dd>

                <dt>
                    <span class="content-size {includeOrSizeClass}"><i class="fal fa-file-code"></i> コンテンツサイズ</span>
                </dt>
                <dd>
                    <span class="include-count {includeClass}">インクルード {data.include} 回</span>
                    <br>
                    <span class="content-size {sizeClass}">
                        {data.contentSize.all === 0 ? 'N/A' : formatBytes(data.contentSize.all)}
                        <small>
                          (本文: {data.contentSize.body === 0 ? 'N/A' : formatBytes(data.contentSize.body)}
                          / 他: {(data.contentSize.all === 0 || data.contentSize.body === 0)
                          ? 'N/A'
                          : formatBytes(data.contentSize.all - data.contentSize.body)})
                        </small>
                        {#if data.contentSize.all / SIZE_WARNING_THRESHOLD.danger >= 1/16}
                            <br>
                            <span class="bar-container">
                                <span class="bar-value" style="width: {Math.ceil((data.contentSize.all / SIZE_WARNING_THRESHOLD.danger) * 100)}%;"/>
                            </span>
                        {/if}
                    </span>
                    {#if sizeWarningLevel === 3}
                        <p class="content-size-warning">
                            変換後の HTML が {formatBytes(SIZE_WARNING_THRESHOLD.danger)} を超えています。<br>通信量節約のためページ構成の見直しをお願い致します。
                        </p>
                    {/if}
                    {#if includeWarningLevel === 3}
                        <p class="content-size-warning">
                            インクルード回数が {INCLUDE_WARNING_THRESHOLD.danger} を超えています。ページ構成の見直しをお願い致します。
                        </p>
                    {/if}
                </dd>

                <dt>
                    <span class="secondary-resources"><i class="fas fa-link"></i> 読み込みリソース数</span>
                </dt>
                <dd>
                    {#if resources.all === 0}
                        なし
                    {:else}
                        <span>{resources.all}</span> <small>(本文のみ: {resources.body} / 他: {resources.others})</small>
                    {/if}
                </dd>
            </dl>
            <div class="timestamp">サーバー処理時刻 {data.timestamp}</div>
        </div>
    </div>
</FloatingPanel>


<!--suppress CssUnusedSymbol -->
<style>
    .load-panel-trigger {
        & .pageload-time-all-ms {
            font-size: 11px;
        }

        &:hover .pageload-time-all-ms {
            text-decoration: underline;
        }
    }

    .loadpanel {
        font-size: 12px;

        & .loadpanel-help {
            float: right;
            font-size: 14px;
            margin: -15px -10px 0 0;

            & a {
                color: #ccc;
                text-decoration: none;
                border: none;

                &:hover, &:active {
                    color: #888;
                    text-decoration: none;
                    border: none;
                }
            }
        }

        & dl {
            color: #333;
            line-height: 1rem;
        }

        & dt {
            color: #333;
            font-weight: bold;
            margin: 1rem 0 0.2rem;
            padding: 0;

            &:first-child {
                margin-top: 0.2rem;
            }
        }

        & dd {
            color: #333;
            margin: 0.2rem 0;
            padding: 0 0 0 0.8rem;
        }

        & .timestamp {
            font-size: 9px;
            color: #888888;
            margin-top: 1.3em;
        }

        & .bar-container {
            display: inline-block;
            position: relative;
            overflow: hidden;
            width: 8em;
            height: 6px;
            background-color: #eeeeee;
            border: 1px solid #aaaaaa;
            margin: 2px 0;

            & .bar-value {
                position: absolute;
                height: 100%;
                width: 0;
                background-color: #008888;
            }
        }

        & .danger {
            color: #dc3545;

            & .bar-value {
                background-color: #dc3545;
            }
        }
        & .warning {
            color: #FF7F00;

            & .bar-value {
                background-color: #FF7F00;
            }
        }
        & .safe {
            color: #28a745;

            & .bar-value {
                background-color: #28a745;
            }
        }
    }

</style>
