<script lang="ts">
  import {groupRecentDataByBoundaries, RecentChangeData} from '../../lib/recent-data'

  export let recentChangeDataList: RecentChangeData[]
  export let groupBoundary: string[]

  const groupedData = groupRecentDataByBoundaries(recentChangeDataList, groupBoundary)
</script>

<h5>最新の{recentChangeDataList.length}件</h5>
<div class="recentdetail-container">
  {#each Object.entries(groupedData.relativeBoundary) as [label, items]}
    <div>
      <strong><span class="recentdetail" style="color: #973D21">{label}</span></strong>
    </div>
    <ul>
      {#each items as item}
        <li>
          <a href={item.link} title={item.page} class="rel-wiki-page">{item.page}</a>
        </li>
      {/each}
    </ul>
  {/each}
  <hr class="short_line">
  {#each Object.entries(groupedData.dailyBoundary) as [date, items]}
    <div>
      <strong>{date}</strong>
    </div>
    <ul>
      {#each items as item}
        <li>
          <a href={item.link} title={item.page} class="rel-wiki-page">{item.page}</a>
        </li>
      {/each}
    </ul>
  {/each}
</div>
