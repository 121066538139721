<script lang="ts">
    import Drawer from './lib/Drawer.svelte'
    import FloatingButton from './lib/FloatingButton.svelte'

    // props
    export let sourceSelector: string
    export let label: string = "メニュー"
    export let width: number = 280
    export let side: 'left'|'right' = 'right'
    export let buttonType: 'circle'|'square' = 'circle'
    export let bottomPadding: number|null = null

    // local state
    let isOpened: boolean = false

    // ref
    let placeholder: HTMLElement|null = null

    const source: HTMLElement|null = document.querySelector(sourceSelector)
    const sourceParent = source?.parentElement
    const sourceNextSibling = source?.nextSibling

    function moveDomElement(
        element:HTMLElement|null,
        parent:HTMLElement|null,
        before:ChildNode|null = null
    ) {
        if (!element || !parent) return
        // このようになっているので、絶対にアンマウントしない用法で使うこと!!
        // このファイルに書かれた DOM は復元できても、元のコンテンツは失われてしまう。
        element.remove()
        parent.insertBefore(element, before)
    }

    function openDrawer() {
        isOpened = true
        moveDomElement(source, placeholder)
    }

    function closeDrawer() {
        isOpened = false
        moveDomElement(source, sourceParent, sourceNextSibling)
    }
</script>

<svelte:window on:resize={closeDrawer} />

<FloatingButton {label} type={buttonType} on:click={openDrawer}>
    <slot>?</slot>
</FloatingButton>
<Drawer open={isOpened} {side} on:close={closeDrawer} {bottomPadding}>
    <div class="dom-source-placeholder" style:width={width + 'px'} bind:this={placeholder}></div>
</Drawer>
