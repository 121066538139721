import ResponsiveNavigation from './components/responsive/ResponsiveNavigation.svelte'

interface ResponsiveNavigationParams {
    menuBarSelector: string,
    editMenuSelector: string,

    mainContentsBottomElement: string,
    sidebarBottomElement: string,
    footerElement: string,

    overlayAdRenderedEventName: string,
    overlayAdMarginTop: number,
}

$.fn.extend({
    'responsiveNavigation': function (this:JQuery, options:ResponsiveNavigationParams) {
        options = $.extend({
            menuBarSelector: '#menubar',
            editMenuSelector: '#edit-menu',
            mainContentsBottomElement: '#main-contents-bottom',
            sidebarBottomElement: '#sidebar-bottom',
            footerElement: '#footer',
            overlayAdRenderedEventName: null,
            overlayAdMarginTop: 0
        }, options)

        this.each(() => {
            new ResponsiveNavigation({
                target: this[0],
                props: {
                    menuBarSelector: options.menuBarSelector,
                    editMenuSelector: options.editMenuSelector,
                    scrollBottomSelectors: {
                        menubar: options.menuBarSelector,
                        mainContentsBottom: options.mainContentsBottomElement,
                        sidebarBottom: options.sidebarBottomElement,
                        footer: options.footerElement,
                    },
                    overlayAd: {
                        renderedEventName: options.overlayAdRenderedEventName,
                        marginTop: options.overlayAdMarginTop,
                    },
                }
            })
        })
    }
})
