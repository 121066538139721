<script lang="ts">
    import {groupByDate, RecentChangeData} from '../../lib/recent-data'

    export let recentChangeDataList: RecentChangeData[]

    const groupedData = groupByDate(recentChangeDataList);

</script>

<h5>最新の{recentChangeDataList.length}件</h5>
<div>
    {#each Object.entries(groupedData) as [date, items]}
        <strong>{date}</strong>
        <ul class="recent_list">
            {#each items as item}
                <li>
                    <a href={item.link} title={item.page} class="rel-wiki-page">{item.page}</a>
                </li>
            {/each}
        </ul>
    {/each}
</div>
